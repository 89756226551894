<template>
  <section class="obras-finalizadas overflow-auto scroll-none container-fluid">
    <div class="row">
      <div class="col-12">
        <titulo-divisor titulo="Lista de obras terminadas">
            <div class="row">
                <div class="col-auto px-1 d-none">
                    <el-input v-model="buscarListado" placeholder="Buscar taller" size="small" />
                </div>
                <filter-indicator :count="filterCount" @openModalFilter="openModalFilter" @clearFilter="handleClearFilter"/>
            </div>
        </titulo-divisor>
        <tabla-general altura-tabla="calc(100vh - 22rem)" :mostrarBuscador="false" :data="obras" :usar-paginacion="false">
            <el-table-column label="Proyecto" prop="proyecto" min-width="250" label-class-name="pl-3">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2 tres-puntos f-600 pl-4">
                        {{ scope.row.proyecto }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Cliente" prop="cliente" width="150">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2">
                        {{ scope.row.cliente }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Estado" prop="estado" align="center" width="180">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2">
                        {{ scope.row.estado }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Fecha de inicio" prop="fecha_inicio" align="center" width="180">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2">
                        {{ scope.row.fecha_inicio | helper-fecha('DD MMM. YYYY') }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Fecha final" prop="fecha_final" align="center" width="150">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2">
                        {{ scope.row.fecha_fin | helper-fecha('DD MMM. YYYY') }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Valor" prop="valor" align="center" width="100">
                <template slot-scope="scope">
                    {{ formatoMoneda(scope.row.valor) }}
                </template>
            </el-table-column>
        </tabla-general>
      </div>
    </div>
    <!-- Pagination -->
    <div class="row justify-content-center" >
        <div class="col-auto">
            <Pagination :pagination="pagination" @paginate="Action_get_finished_obras($event)"/>
        </div>
    </div>
    <!-- partials -->
    <modal-lateral ref="modalFilter" titulo="Filtro">
        <div class="row mx-0 justify-content-center my-2">
            <div class="col-10">
                <p class="input-label-top">Cliente</p>
                <el-select v-model="filter.id_cliente" placeholder="Seleccionar cliente" size="small" class="w-100" clearable>
                    <el-option v-for="(item, index) in select_clients" :key="index" :label="item.nombre_corto" :value="item.id" />
                </el-select>
            </div>
            <div class="col-5 my-4">
                <p class="input-label-top">Fecha de inicio</p>
                <el-date-picker v-model="filter.fecha_inicio" type="date" placeholder="Seleccionar un día" size="small" class="w-100" />
            </div>
            <div class="col-5 my-4">
                <p class="input-label-top">Fecha final</p>
                <el-date-picker v-model="filter.fecha_fin" type="date" placeholder="Seleccionar un día" size="small" class="w-100" />
            </div>
            <div class="col-10 mb-4">
                <p class="input-label-top">Rango de valor</p>
                <el-slider v-model="range_value" range :min="range.min" :max="range.max" :format-tooltip="formatTooltipRange" />
            </div>
        </div>
        <div class="botones">
            <button type="button" class="btn mx-2 btn-limpiar f-12 h-32px" @click="handleClearFilter"> Limpiar </button>
            <button type="button" class="btn mx-2 btn-general w-80px f-12 h-32px text-white" @click="filterMaterials"> Filtrar </button>
        </div>
    </modal-lateral>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    data(){
        return{
            buscarListado:'',
        }
    },
    async created(){
        await this.Action_get_finished_obras_range()
        this.clearFilter()
        await this.Action_get_finished_obras()
        this.getSelects(['Action_get_select_clients'])
    },
    computed: {
        ...mapGetters({
            range: 'obras/finalizadas/range',
            obras: 'obras/finalizadas/obras',
            pagination: 'obras/finalizadas/pagination',
            select_clients: 'selects/selects/select_clients'
        }),
        filter:{
            get(){
                return this.$store.getters['obras/finalizadas/filter']
            },
            set(val){
                this.$store.commit('obras/finalizadas/set_filter', val)
            }
        },
        range_value:{
            set(val){
                this.filter.valor_minimo = val[0]
                this.filter.valor_maximo = val[1]
            },
            get(){
                return [
                    this.filter.valor_minimo,
                    this.filter.valor_maximo
                ]
            }
        },
        filterCount(){
            return this.filter.count()
        }
    },
    methods:{
        ...mapActions({
            Action_get_finished_obras: 'obras/finalizadas/Action_get_finished_obras',
            Action_get_finished_obras_range: 'obras/finalizadas/Action_get_finished_obras_range',
            clearFilter: 'obras/finalizadas/clear_filter',
            getSelects: 'selects/selects/sync'
        }),
        openModalFilter(){
            this.$refs.modalFilter.toggle()
        },
        async filterMaterials(){
            await this.Action_get_finished_obras()
            this.$refs.modalFilter.toggle()
        },
        async handleClearFilter(){
            await this.clearFilter()
            this.Action_get_finished_obras()
        },
        formatTooltipRange(val){
            return 'US $' + val
        },
    }

}
</script>

<style lang="scss" scoped>
.obras-finalizadas{
    height: calc(100vh - 225px);
}
</style>